import {
  create_cc_company,
  delete_cc_company,
  query_cc_company_list,
  recover_cc_company,
  update_cc_company,
} from '@/api/baseinfo-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/baseinfo/baseinfo-cccompany-config';

import { reactive } from 'vue';
import { getDateTime } from '@/utils/function';
import { notification } from 'ant-design-vue';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '公司名称',
      label_i18n: t('page.baseinfo.cccompany.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写账户名',
      placeholder_i18n: t('page.baseinfo.cccompany.form.placeholder.name'),
    },
    {
      type: 'select',
      name: 'is_active',
      label: '是否可用',
      label_i18n: t('page.baseinfo.cccompany.form.label.is_active'),
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择是否可用',
      placeholder_i18n: t('page.baseinfo.cccompany.form.placeholder.is_active'),
      datasource: 'is_active',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: t('全部') },
    { value: True, name: t('可用') },
    { value: False, name: t('禁用') },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '公司名称',
      label_i18n: t('page.baseinfo.cccompany.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写账户名',
      placeholder_i18n: t('page.baseinfo.cccompany.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'abbr',
      label: '公司缩写',
      label_i18n: t('page.baseinfo.cccompany.form.label.abbr'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写公司缩写',
      placeholder_i18n: t('page.baseinfo.cccompany.form.placeholder.abbr'),
    },
    {
      type: 'input',
      name: 'address',
      label: '地址',
      label_i18n: t('page.baseinfo.cccompany.form.label.address'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写地址',
      placeholder_i18n: t('page.baseinfo.cccompany.form.placeholder.address'),
    },
    {
      type: 'input',
      name: 'contact_number',
      label: '联系方式',
      label_i18n: t('page.baseinfo.cccompany.form.label.contact_number'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写联系方式',
      placeholder_i18n: t('page.baseinfo.cccompany.form.placeholder.contact_number'),
    },
    {
      type: 'remoteselect',
      name: 'country_id',
      label: '国家',
      label_i18n: t('page.baseinfo.cccompany.form.label.country_id'),
      disabled: false,
      placeholder: '请选择国家',
      placeholder_i18n: t('page.baseinfo.cccompany.form.placeholder.country_id'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'name',
      valueKey: 'id',
      modalType: 'baseinfo-country',
    },
    {
      type: 'number',
      name: 'bank_charge',
      label: '银行转账费',
      label_i18n: t('银行转账费'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写银行转账费',
      placeholder_i18n: t('请填写银行转账费'),
    },
    {
      type: 'number',
      name: 'payment_term',
      label: '付款周期',
      label_i18n: t('付款周期'),
      placeholder: '请填写付款周期',
      placeholder_i18n: t('请填写付款周期'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
    },
    {
      type: 'input',
      name: 'website',
      label: '网址',
      label_i18n: t('网址'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写网址',
      placeholder_i18n: t('请填写网址'),
    },
    {
      type: 'input',
      name: 'email',
      label: '邮箱地址',
      label_i18n: t('page.baseinfo.ccemail.form.label.email'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写邮箱地址',
      placeholder_i18n: t('page.baseinfo.ccemail.form.placeholder.email'),
    },
  ],
  rules: {
    name: [{ required: true, message: '请填输入公司名称' }],
    address: [{ required: true, message: '请填地址' }],
    country_id: [{ required: true, message: '请选择国家' }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    address: null,
    contact_number: null,
    country_id: null,
    bank_charge: null,
    abbr: null,
    payment_term: 15,
    website: null,
    email: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '公司名称',
      label_i18n: t('page.baseinfo.cccompany.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写账户名',
      placeholder_i18n: t('page.baseinfo.cccompany.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'abbr',
      label: '公司缩写',
      label_i18n: t('page.baseinfo.cccompany.form.label.abbr'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写公司缩写',
      placeholder_i18n: t('page.baseinfo.cccompany.form.placeholder.abbr'),
    },
    {
      type: 'input',
      name: 'address',
      label: '地址',
      label_i18n: t('page.baseinfo.cccompany.form.label.address'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写地址',
      placeholder_i18n: t('page.baseinfo.cccompany.form.placeholder.address'),
    },
    {
      type: 'input',
      name: 'contact_number',
      label: '联系方式',
      label_i18n: t('page.baseinfo.cccompany.form.label.contact_number'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写联系方式',
      placeholder_i18n: t('page.baseinfo.cccompany.form.placeholder.contact_number'),
    },
    {
      type: 'remoteselect',
      name: 'country_id',
      label: '国家',
      label_i18n: t('page.baseinfo.cccompany.form.label.country_id'),
      disabled: false,
      placeholder: '请选择国家',
      placeholder_i18n: t('page.baseinfo.cccompany.form.placeholder.country_id'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'name',
      valueKey: 'id',
      modalType: 'baseinfo-country',
    },
    {
      type: 'number',
      name: 'bank_charge',
      label: '银行转账费',
      label_i18n: t('银行转账费'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写银行转账费',
      placeholder_i18n: t('请填写银行转账费'),
    },
    {
      type: 'number',
      name: 'payment_term',
      label: '付款周期',
      label_i18n: t('付款周期'),
      placeholder: '请填写付款周期',
      placeholder_i18n: t('请填写付款周期'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
    },
    {
      type: 'input',
      name: 'website',
      label: '网址',
      label_i18n: t('网址'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写网址',
      placeholder_i18n: t('请填写网址'),
    },
    {
      type: 'input',
      name: 'email',
      label: '邮箱地址',
      label_i18n: t('page.baseinfo.ccemail.form.label.email'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写邮箱地址',
      placeholder_i18n: t('page.baseinfo.ccemail.form.placeholder.email'),
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    address: null,
    contact_number: null,
    country_id: null,
    bank_charge: null,
    abbr: null,
    payment_term: null,
    website: null,
    email: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: t('CC公司管理'),
  addModalTitle: t('新建CC公司'),
  updateTitle: t('修改CC公司'),
  is_create: true, // 是否有【新建】按钮
  is_update: true, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    cc_company_id: 'id',
  },
  delete: {
    cc_company_id: 'id',
  },
  recover: {
    cc_company_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    cc_company_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.baseinfo.cccompany.column.title.index'),
    dataIndex: 'index',
    width: 50,
  },
  {
    title: t('page.baseinfo.cccompany.column.title.id'),
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('page.baseinfo.cccompany.column.title.name'),
    dataIndex: 'name',
    checked: true,
  },
  {
    title: t('page.baseinfo.cccompany.column.title.abbr'),
    dataIndex: 'abbr',
    checked: true,
  },
  {
    title: t('page.baseinfo.cccompany.column.title.address'),
    dataIndex: 'address',
    checked: true,
  },
  {
    title: t('page.baseinfo.cccompany.column.title.contact_number'),
    dataIndex: 'contact_number',
    checked: true,
  },
  {
    title: t('付款周期'),
    dataIndex: 'payment_term',
    checked: true,
  },
  {
    title: t('国家'),
    dataIndex: 'country__name',
    checked: true,
  },
  {
    title: t('银行转账费'),
    dataIndex: 'bank_charge',
    checked: true,
  },
  {
    title: t('网址'),
    dataIndex: 'website',
    checked: true,
  },
  {
    title: t('邮箱地址'),
    dataIndex: 'email',
    checked: true,
  },
  {
    title: 'logo',
    minWidth: 200,
    dataIndex: 'logo',
    checked: true,
    cellComponent: 'click',
    content: (record: any) => {
      if (record.logo_files_json && record.logo_files_json.name) {
        return record.logo_files_json.name;
      } else {
        return '';
      }
    },
    cb: (record: any) => {
      if (record.logo) {
        const url: string =
          'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' +
          encodeURIComponent(record.logo_files_json.file_url);
        window.open(url, '_blank');
      } else {
        notification.error({
          message: t('提示'),
          description: '未上传logo',
        });
      }
    },
  },
  {
    title: t('page.baseinfo.contracttemplate.column.title.modal1'),
    minWidth: 200,
    dataIndex: 'modal1',
    checked: true,
    cellComponent: 'click',
    content: (record: any) => {
      if (record.files_json && record.files_json.name) {
        return record.files_json.name;
      } else {
        return '';
      }
    },
    cb: (record: any) => {
      if (record.files_json && record.files_json.file_url) {
        const url: string =
          'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' +
          encodeURIComponent(record.files_json.file_url);
        window.open(url, '_blank');
      } else {
        notification.error({
          message: t('提示'),
          description: '未上传合同模板',
        });
      }
    },
  },
  {
    title: t('请款单模板'),
    minWidth: 200,
    dataIndex: 'modal2',
    checked: true,
    cellComponent: 'click',
    content: (record: any) => {
      if (record.invoice_files_json && record.invoice_files_json.name) {
        return record.invoice_files_json.name;
      } else {
        return '';
      }
    },
    cb: (record: any) => {
      if (record.invoice_files_json && record.invoice_files_json.file_url) {
        const url: string =
          'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' +
          encodeURIComponent(record.invoice_files_json.file_url);
        window.open(url, '_blank');
      } else {
        notification.error({
          message: t('提示'),
          description: '未上传合同模板',
        });
      }
    },
  },
  {
    title: t('工资单模板'),
    minWidth: 200,
    dataIndex: 'modal3',
    checked: true,
    cellComponent: 'click',
    content: (record: any) => {
      if (record.payslip_files_json && record.payslip_files_json.name) {
        return record.payslip_files_json.name;
      } else {
        return '';
      }
    },
    cb: (record: any) => {
      if (record.payslip_files_json && record.payslip_files_json.file_url) {
        const url: string =
          'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' +
          encodeURIComponent(record.payslip_files_json.file_url);
        window.open(url, '_blank');
      } else {
        notification.error({
          message: t('提示'),
          description: '未上传合同模板',
        });
      }
    },
  },
  {
    title: t('page.baseinfo.cccompany.column.title.update_time'),
    dataIndex: 'update_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },
  {
    title: t('page.baseinfo.cccompany.column.title.is_active'),
    dataIndex: 'is_active',
    checked: true,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },
  {
    title: t('page.baseinfo.cccompany.column.title.action'),
    width: 170,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push();

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_cc_company_list,
    addData: create_cc_company,
    updateData: update_cc_company,
    removeData: delete_cc_company,
    recoverData: recover_cc_company,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};

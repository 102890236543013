import {
  create_extra_field,
  delete_extra_field,
  query_extra_field_list,
  recover_extra_field,
  update_extra_field,
} from '@/api/cerp-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_COMPANY, STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/cerp/cerp-extra_field-config';

import { reactive } from 'vue';
import { useRoute } from 'vue-router';
import { getDateTime, getMonth } from '@/utils/function';

const None = null;
const True = true;
const False = false;
export default () => {
  const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
  const current_company = ls.get(STORAGE_CURRENT_COMPANY); // 当前组织org

  const { t } = useI18n();
  const search_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'inline',
    },
    fields: [
    {
      type: 'remotemodal',
      name: 'adviser_id',
      label: '顾问',
      label_i18n: t('page.cerp.todoevent.form.label.adviser_id'),
      disabled: false,
      allowClear: true,
      defaultValue: '',
      labelKey: 'full_name',
      valueKey: 'id',
      placeholder: '隶属顾问',
      placeholder_i18n: t('page.cerp.todoevent.form.placeholder.adviser_id'),
      show_search: false,
      maxTagCount: 5,
      mode: 'default',
      title: t('选择顾问'),
      modalType: 'cerp-adviser',
    },
    {
      type: 'input',
      name: 'cc_company_name',
      label: 'CC公司',
      label_i18n: t('page.cerp.contract.form.label.cc_company_id'),
      disabled: true,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写CC公司',
    },
    {
      type: 'input',
      name: 'custom_field_name',
      label: '自定义项描述',
      label_i18n: '自定义项描述',
      placeholder_i18n: '请填写自定义项描述',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写自定义项描述',
    },

    {
      type: 'select',
      name: 'is_finished',
      label: '是否完成',
      label_i18n: t('page.cerp.todoevent.form.label.is_finished'),
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择是否完成',
      placeholder_i18n: t('page.cerp.todoevent.form.placeholder.is_finished'),
      datasource: 'is_finished',
    },
      {
        type: 'radio',
        name: 'type',
        label: '类型',
        label_i18n: '类型',
        mode: 'default',
        disabled: false,
        defaultValue: '',
        labelKey: 'name',
        valueKey: 'value',
        datasource: 'type',
      },
      {
        type: 'monthpicker',
        name: 'month',
        label: '月份',
        label_i18n: '月份',
        placeholder_i18n: '请选择月份',
        disabled: false,
        allowClear: true,
        inputType: 'number',
        defaultValue: '',
        showTime: false,
        valueFormat: 'YYYY-MM-DD',
        format: 'YYYY-MM',
        placeholder: '请填写月份',
      },
      // {
      //   type: 'remoteselect',
      //   name: 'cc_company_id',
      //   label: 'CC公司',
      //   label_i18n: t('page.cerp.contract.form.label.cc_company_id'),
      //   disabled: false,
      //   placeholder: '请选择CC公司',
      //   placeholder_i18n: t('page.cerp.contract.form.placeholder.cc_company_id'),
      //   datasourceType: 'remote',
      //   mode: 'default',
      //   allowClear: true,
      //   modalType: 'baseinfo-cccompany',
      // },
      {
        type: 'select',
        name: 'is_active',
        label: '状态',
        label_i18n: t('page.cerp.todoevent.form.label.is_active'),
        mode: 'default',
        disabled: false,
        defaultValue: '',
        labelKey: 'name',
        valueKey: 'value',
      placeholder: '请选择状态',
      placeholder_i18n: t('page.cerp.todoevent.form.placeholder.is_active'),
      datasource: 'is_active',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    adviser_id: null,
    custom_field_name: '',
    cc_company_id: current_company?.id,
    cc_company_name: current_company?.name !== '全部公司' ? current_company?.name : '',
    is_finished: false,
    type: null,
    month: null,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: t('全部') },
    {
      value: True,
      name: t('可用'),
    },
    { value: False, name: t('禁用') },
  ],
  is_finished: [
    { value: None, name: t('全部') },
    {
      value: True,
      name: t('完成'),
    },
    { value: False, name: t('未完成') },
  ],
  type: [
    {
      value: 0,
      name: t('请款单'),
    },
    { value: 1, name: t('工资单') },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'radio',
      name: 'type',
      label: '类型',
      label_i18n: '类型',
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      datasource: 'type',
    },
    {
      type: 'remotemodal',
      name: 'adviser_id',
      label: '顾问',
      label_i18n: t('page.cerp.todoevent.form.label.adviser_id'),
      disabled: false,
      allowClear: true,
      defaultValue: '',
      labelKey: 'full_name',
      valueKey: 'id',
      placeholder: '隶属顾问',
      placeholder_i18n: t('page.cerp.todoevent.form.placeholder.adviser_id'),
      show_search: false,
      maxTagCount: 5,
      mode: 'default',
      title: t('选择顾问'),
      modalType: 'cerp-adviser',
    },
    {
      type: 'input',
      name: 'custom_field_name',
      label: '自定义项描述',
      label_i18n: '自定义项描述',
      placeholder_i18n: '请填写自定义项描述',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写自定义项描述',
    },
    {
      type: 'input',
      name: 'custom_field_value',
      label: '自定义项值',
      label_i18n: '自定义项值',
      placeholder_i18n: '请填写自定义项值',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写自定义项值',
    },
    {
      type: 'input',
      name: 'content',
      label: '内容',
      label_i18n: t('page.cerp.todoevent.form.label.content'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写内容',
      placeholder_i18n: t('page.cerp.todoevent.form.placeholder.content'),
    },
    {
      type: 'monthpicker',
      name: 'month',
      label: '月份',
      label_i18n: '月份',
      placeholder_i18n: '请选择月份',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      format: 'YYYY-MM',
      placeholder: '请填写月份',
    },
    {
      type: 'remoteselect',
      name: 'cc_company_id',
      label: 'CC公司',
      label_i18n: 'CC公司',
      placeholder_i18n: '请选择CC公司',
      disabled: false,
      placeholder: '请选择CC公司',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-cccompany',
    },
    {
      type: 'textarea',
      name: 'note',
      label: '备注',
      label_i18n: '备注',
      placeholder_i18n: '请填写备注',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      maxlength: 200,
      defaultValue: '',
      placeholder: '请填写备注',
    },
  ],
  rules: {
    title: [{ required: true, message: '请填输入标题' }],
    custom_field_name: [{ required: true, message: '请填写自定义项描述' }],
    custom_field_value: [{ required: true, message: '请填写自定义项值' }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    title: null,
    content: null,
    is_finished: null,
    adviser_id: null,
    alarm_time: null,
    alarm_number: null,
    todo_priority_id: null,
    cc_company_id: null,
  }),
};
const edit_model_config_options = {
  is_finished: [
    { value: True, name: '完成' },
    { value: False, name: '未完成' },
  ],
  type: [
    {
      value: 0,
      name: '请款单',
    },
    { value: 1, name: '工资单' },
  ],
};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'title',
      label: '标题',
      label_i18n: t('page.cerp.todoevent.form.label.title'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写标题',
      placeholder_i18n: t('page.cerp.todoevent.form.placeholder.title'),
    },
    {
      type: 'input',
      name: 'content',
      label: '内容',
      label_i18n: t('page.cerp.todoevent.form.label.content'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写内容',
      placeholder_i18n: t('page.cerp.todoevent.form.placeholder.content'),
    },
    {
      type: 'radio',
      name: 'is_finished',
      label: '是否完成',
      label_i18n: t('page.cerp.todoevent.form.label.is_finished'),
      disabled: false,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
    },
    {
      type: 'remotemodal',
      name: 'adviser_id',
      label: '顾问',
      label_i18n: t('page.cerp.todoevent.form.label.adviser_id'),
      disabled: false,
      defaultValue: '',
      labelKey: 'full_name',
      valueKey: 'id',
      placeholder: '隶属顾问',
      placeholder_i18n: t('page.cerp.todoevent.form.placeholder.adviser_id'),
      show_search: false,
      maxTagCount: 5,
      mode: 'default',
      title: t('选择顾问'),
      modalType: 'cerp-adviser',
    },
    {
      type: 'datepicker',
      name: 'alarm_time',
      label: '提醒时间',
      label_i18n: '提醒时间',
      placeholder_i18n: '请填写提醒时间',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: true,
      valueFormat: 'YYYY-MM-DD HH:mm:00',
      placeholder: '请填写提醒时间',
    },
    {
      type: 'number',
      name: 'alarm_number',
      label: '提醒间隔天数',
      label_i18n: '提醒间隔天数',
      placeholder_i18n: '请填写提醒间隔天数',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: 0,
      placeholder: '请填写提醒间隔天数',
    },
    {
      type: 'remoteselect',
      name: 'todo_priority_id',
      label: '优先等级',
      label_i18n: '优先等级',
      placeholder_i18n: '请选择优先等级',
      disabled: false,
      placeholder: '请选择优先等级',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'name',
      valueKey: 'id',
      modalType: 'baseinfo.todoevent',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    title: null,
    content: null,
    is_finished: null,
    adviser_id: null,
    alarm_time: null,
    alarm_number: null,
    todo_priority_id: null,
  }),
};
const update_model_config_options = {
  is_finished: [
    { value: True, name: '完成' },
    { value: False, name: '未完成' },
  ],
};

const titleList = {
  title: t('增扣提醒管理'),
  addModalTitle: '新建增扣提醒',
  updateTitle: '修改',
  is_create: false, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    extra_field_id: 'id',
  },
  delete: {
    extra_field_id: 'id',
  },
  recover: {
    extra_field_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    extra_field_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.cerp.todoevent.column.title.index'),
    dataIndex: 'index',
    width: 50,
  },
  {
    title: t('自定义项描述'),
    width: 150,
    dataIndex: 'custom_field_name',
    checked: true,
  },
  {
    title: t('自定义项值'),
    width: 150,
    dataIndex: 'custom_field_value',
    checked: true,
  },
  {
    title: t('顾问'),
    width: 150,
    dataIndex: 'adviser__full_name',
    checked: true,
  },
  {
    title: t('类型'),
    width: 80,
    dataIndex: 'type',
    checked: true,
    cellComponent: 'tag',
    content: (record: any) => {
      let color = '';
      switch (record.type) {
        case 0:
          color = t('请款单');
          break;
        case 1:
          color = t('工资单');
          break;
        default:
          color = '';
          break;
      }
      return color;
    },
    color: (record: any) => {
      let color = '';
      switch (record.type) {
        case 0:
          color = 'orange';
          break;
        case 1:
          color = 'blue';
          break;
        default:
          color = '';
          break;
      }
      return color;
    },
    filterMultiple: false,
    filters: [
      { value: null, text: t('全部') },
      { value: 0, text: t('请款单') },
      { value: 1, text: t('工资单') },
    ],
  },
  {
    title: t('月份'),
    width: 80,
    dataIndex: 'month',
    checked: true,
    content: (record: any, text: any) => {
      return getMonth(text);
    },
  },
  {
    title: t('内容'),
    width: 200,
    dataIndex: 'content',
    checked: true,
  },
  {
    title: t('CC公司'),
    width: 200,
    dataIndex: 'cc_company__name',
    checked: true,
  },
  {
    title: t('状态'),
    width: 80,
    dataIndex: 'is_finished',
    checked: true,
    cellComponent: 'boolean',
    name1: t('完成'),
    name2: t('未完成'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('完成'), value: true },
      { text: t('未完成'), value: false },
    ],
  },
  {
    title: t('page.cerp.todoevent.column.title.create_time'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,
    sorter: {
      multiple: 1,
    },
    content: (record: any) => {
      return getDateTime(record.create_time);
    },
  },
  {
    title: t('page.cerp.todoevent.column.title.update_time'),
    width: 150,
    dataIndex: 'update_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },
  {
    title: t('page.cerp.todoevent.column.title.is_active'),
    width: 90,
    dataIndex: 'is_active',
    checked: true,

    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },

  {
    title: t('page.cerp.todoevent.column.title.action'),
    width: 120,
    checked: false,
    dataIndex: 'action',
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push();


  const route = useRoute();
  search_config.model = reactive({ ...search_config.model });
  search_config.model.ids = Number(route.query.ids);
  search_config.model.adviser_id = Number(route.query.adviser_id);
  search_config.model.cc_company_name = route.query.cc_company_name ? (route.query.cc_company_name !== '全部公司' ? route.query.cc_company_name : '') : (current_company?.name !== '全部公司' ? current_company?.name : '');
  search_config.model.cc_company_id = route.query.cc_company_id ? route.query.cc_company_id : current_company?.id;
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_extra_field_list,
    addData: create_extra_field,
    updateData: update_extra_field,
    removeData: delete_extra_field,
    recoverData: recover_extra_field,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
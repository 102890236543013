import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { reactive } from 'vue';
import { update_remittance } from '@/api/cerp-model';
import { message } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';

export const event_obj = mitt();
export const btn_list = [
  {
    name: '修改',
    clazz: 'primary',
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const editModal = reactive({
        visible: true,
        title: '修改汇款凭证项',
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'remittance_name',
              label: '汇款凭证',
              label_i18n: '汇款凭证',
              placeholder_i18n: '请选择汇款凭证',
              disabled: true,
              allowClear: true,
              inputType: 'text',
              maxlength: 100,
              defaultValue: '',
            },
            {
              type: 'remoteselect',
              name: 'invoice_id',
              label: '请款单',
              label_i18n: '请款单',
              placeholder_i18n: '请款单',
              disabled: false,
              placeholder: '请选择请款单',
              datasourceType: 'remote',
              mode: 'default',
              labelKey: 'invoice_no',
              allowClear: true,
              modalType: 'cerp-invoice',
            },
            {
              type: 'money',
              name: 'amount',
              label: '金额',
              label_i18n: '金额',
              placeholder_i18n: '请填总金额',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              maxlength: 100,
              defaultValue: '',
              placeholder: '请填总金额',
            },
            {
              type: 'textarea',
              name: 'description',
              label: '描述',
              label_i18n: '描述',
              placeholder_i18n: '请填描述',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 100,
              defaultValue: '',
              placeholder: '请填描述',
            },
          ],
          rules: {},
          model: {
            org_id: current_org?.id,
            is_active: true,
            remittance_id: record.id,
            remittance_name: record.remittance_set__remittance_name,
            amount: record.amount,
            description: record.description,
            invoice_id: record.invoice_id,
          },
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            update_remittance(payload)
              .then(() => {
                message.success(t('修改成功'));
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '备注',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'NoteModel',
        params: {
          object_id: record.id,
          note_type_flag: 'cerp.remittance',
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list: btnTd[] = [];

import { create_payslip, delete_payslip, query_payslip_list, recover_payslip, update_payslip } from '@/api/cerp-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/cerp/cerp-payslip-config';
import { reactive } from 'vue';
import { notification } from 'ant-design-vue';
import { getDate, getDateTime } from '@/utils/function';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'remotemodal',
      name: 'adviser_id',
      label: '隶属顾问',
      label_i18n: t('page.cerp.payslip.form.label.adviser_id'),
      disabled: false,
      defaultValue: '',
      labelKey: 'full_name',
      valueKey: 'id',
      placeholder: '隶属顾问',
      placeholder_i18n: t('page.cerp.payslip.form.placeholder.adviser_id'),
      show_search: false,
      maxTagCount: 5,
      mode: 'default',
      title: t('选择隶属顾问'),
      modalType: 'cerp-adviser',
    },
    {
      type: 'remoteselect',
      name: 'invoice_id',
      label: '请款单',
      label_i18n: t('page.cerp.payslip.form.label.invoice_id'),
      disabled: false,

      placeholder: '请选择请款单',
      placeholder_i18n: t('page.cerp.payslip.form.placeholder.invoice_id'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'invoice_no',
      valueKey: 'id',
      modalType: 'cerp-invoice',
    },
    {
      type: 'select',
      name: 'status',
      label: '状态',
      label_i18n: t('page.cerp.payslip.form.label.status'),
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择状态',
      placeholder_i18n: t('page.cerp.payslip.form.placeholder.status'),
      datasource: 'status',
    },
    {
      type: 'select',
      name: 'payment_status',
      label: '付款状态',
      label_i18n: '付款状态',
      placeholder_i18n: '请选择付款状态',
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择付款状态',
      datasource: 'payment_status',
    },
    {
      type: 'remoteselect',
      name: 'currency_id',
      labelKeyList: ['code', 'currency'],
      label: '合同薪资币种',
      label_i18n: '合同薪资币种',
      placeholder_i18n: '请选择合同薪资币种',
      disabled: false,
      placeholder: '请选择合同薪资币种',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-country',
    },
    {
      type: 'select',
      name: 'is_active',
      label: '是否可用',
      label_i18n: t('page.cerp.payslip.form.label.is_active'),
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择是否可用',
      placeholder_i18n: t('page.cerp.payslip.form.placeholder.is_active'),
      datasource: 'is_active',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    invoice_id: null,
    status: null,
    payment_status: null,
    cc_bank_account_id: null,
    adviser_id: null,
    currency_id: null,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: '全部' },
    { value: True, name: '可用' },
    { value: False, name: '禁用' },
  ],
  status: [
    { value: None, name: '全部' },
    { name: t('新创建'), value: 1 },
    { name: t('已发送'), value: 2 },
    { name: t('已确认'), value: 3 },
    { name: t('生成PDF'), value: 4 },
  ],
  payment_status: [
    { text: t('全部'), value: None },
    { value: 0, text: t('未收到款项') },
    { value: 1, text: t('收到款项') },
    { value: 2, text: t('已付款') },
    { value: 3, text: t('垫付') },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'datepicker',
      name: 'date',
      label: '收到日期',
      label_i18n: t('page.cerp.payslip.form.label.date'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写收到日期',
      placeholder_i18n: t('page.cerp.payslip.form.placeholder.date'),
    },
    {
      type: 'remoteselect',
      name: 'adviser_id',
      label: '隶属顾问',
      label_i18n: t('page.cerp.payslip.form.label.adviser_id'),
      disabled: false,
      placeholder: '请选择隶属顾问',
      placeholder_i18n: t('page.cerp.payslip.form.placeholder.adviser_id'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'full_name',
      valueKey: 'id',
      modalType: 'cerp-adviser',
    },
    {
      type: 'input',
      name: 'custom_4',
      label: '自定义4',
      label_i18n: t('page.cerp.payslip.form.label.custom_4'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写自定义4',
      placeholder_i18n: t('page.cerp.payslip.form.placeholder.custom_4'),
    },
    {
      type: 'money',
      name: 'referral_fees',
      label: '推荐费',
      label_i18n: t('page.cerp.payslip.form.label.referral_fees'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写推荐费',
      placeholder_i18n: t('page.cerp.payslip.form.placeholder.referral_fees'),
    },
    {
      type: 'input',
      name: 'remark',
      label: '备注',
      label_i18n: t('page.cerp.payslip.form.label.remark'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写备注',
      placeholder_i18n: t('page.cerp.payslip.form.placeholder.remark'),
    },
    {
      type: 'remoteselect',
      name: 'cc_bank_account_id',
      label: 'CC银行账户',
      label_i18n: 'CC银行账户',
      placeholder_i18n: '请选择CC银行账户',
      disabled: false,
      placeholder: '请选择CC银行账户',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'account_flag',
      valueKey: 'id',
      modalType: 'baseinfo-ccbankaccountscc',
    },
    {
      type: 'money',
      name: 'bank_charge_cost',
      label: '银行转账费',
      label_i18n: t('page.cerp.payslip.form.label.bank_charge_cost'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写银行转账费',
      placeholder_i18n: t('page.cerp.payslip.form.placeholder.bank_charge_cost'),
    },
    {
      type: 'remoteselect',
      name: 'transaction_id',
      label: '银行流水',
      label_i18n: t('page.cerp.payslip.form.label.transaction_id'),
      disabled: false,
      placeholder: '请选择银行流水',
      placeholder_i18n: t('page.cerp.payslip.form.placeholder.transaction_id'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'cerp-banktransaction',
    },
  ],
  rules: {
    custom_4: [{ required: true, message: '请填输入自定义4' }],
    remark: [{ required: true, message: '请填输入备注' }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    date: null,
    invoice_id: null,
    custom_4: null,
    referral_fees: null,
    remark: null,
    cc_bank_account_id: null,
    bank_charge_cost: null,
    transaction_id: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [],
  rules: {},
  model: reactive({}),
};
const update_model_config_options = {};

const titleList = {
  title: '工资单管理',
  addModalTitle: '新建工资单',
  updateTitle: '修改工资单',
  is_create: false, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    payslip_id: 'id',
  },
  delete: {
    payslip_id: 'id',
  },
  recover: {
    payslip_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    payslip_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.cerp.payslip.column.title.index'),
    dataIndex: 'index',
    width: 50,
    fixed: 'left',
  },
  {
    title: t('page.cerp.payslip.column.title.id'),
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('page.cerp.payslip.column.title.adviser__full_name'),
    width: 150,
    dataIndex: 'adviser__full_name',
    checked: true,
    fixed: 'left',
  },
  {
    title: '工资单号',
    width: 160,
    dataIndex: 'payslip_no',
    checked: true,
    fixed: 'left',
  },
  {
    title: t('page.cerp.payslip.column.title.invoice__invoice_no'),
    width: 160,
    dataIndex: 'invoice__invoice_no',
    checked: true,
    fixed: 'left',
  },
  {
    title: t('page.cerp.invoice.column.title.adviser__employee_number'),
    width: 80,
    dataIndex: 'adviser__employee_number',
    checked: true,
  },
  {
    title: t('page.cerp.invoice.column.title.adviser__end_date'),
    width: 100,
    dataIndex: 'adviser__end_date',
    checked: true,
    content: (record: any) => {
      return getDate(record.adviser__end_date);
    },
  },
  {
    title: t('page.cerp.invoice.column.title.adviser__status'),
    width: 100,
    dataIndex: 'adviser__status',
    checked: true,
    cellComponent: 'custom',
    field_1: '-无-',
    field_2: '工作中',
    field_3: '结束',
    field_4: '申请签证中',
    field_5: '尚未开始',
    field_6: '潜在雇员',
    field_7: '离职',
    field_8: '待定',
  },
  {
    title: t('page.cerp.payslip.column.title.status'),
    width: 100,
    dataIndex: 'status',
    checked: true,
    cellComponent: 'custom',
    field_1: t('新创建'),
    field_2: t('已发送'),
    field_3: t('已确认'),
    field_4: t('生成PDF'),
    filters: [
      { text: t('新创建'), value: 1 },
      { text: t('已发送'), value: 2 },
      { text: t('已确认'), value: 3 },
      { text: t('生成PDF'), value: 4 },
    ],
  },
  {
    title: 'pdf生成状态',
    width: 100,
    dataIndex: 'pdf_status',
    checked: true,
    cellComponent: 'custom',
    field_0: t('未开始'),
    field_1: t('正在生成'),
    field_2: t('失败'),
    field_3: t('成功'),
  },
  {
    title: 'pdf生成日志',
    width: 100,
    dataIndex: 'pdf_log',
    checked: true,
  },
  {
    title: t('page.cerp.payslip.column.title.payment_status'),
    width: 120,
    dataIndex: 'payment_status',
    checked: true,
    cellComponent: 'custom',
    field_0: t('未收到款项'),
    field_1: t('收到款项'),
    field_2: t('已付款'),
    field_3: t('垫付'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { value: 0, text: t('未收到款项') },
      { value: 1, text: t('收到款项') },
      { value: 2, text: t('已付款') },
      { value: 3, text: t('垫付') },
    ],
  },
  {
    title: t('page.cerp.payslip.column.title.email_record__status'),
    width: 120,
    dataIndex: 'email_record__status',
    checked: true,
    cellComponent: 'color',
    cb: (record: any) => {
      if (record.email_record__status == 0 || record.email_record__status == 1) {
        return 'font-weight: bolder';
      } else if (record.email_record__status == 2) {
        return 'color: #FF0000;font-weight: bolder';
      }
    },
    content: (record: any) => {
      if (record.email_record__status == 1) {
        return t('发送成功');
      } else if (record.email_record__status == 2) {
        return t('发送失败');
      } else if (record.email_record__status == 0) {
        return t('已发送');
      }
    },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { value: 0, text: t('已发送') },
      { value: 1, text: t('发送成功') },
      { value: 2, text: t('发送失败') },
    ],
  },
  {
    title: t('page.cerp.payslip.column.title.email_record__log'),
    width: 120,
    dataIndex: 'email_record__log',
    checked: true,
  },
  {
    title: t('page.cerp.payslip.column.title.email_record__create_time'),
    width: 120,
    dataIndex: 'email_record__create_time',
    checked: true,
  },
  {
    title: t('page.cerp.payslip.column.title.files_json'),
    width: 200,
    dataIndex: 'files_json',
    checked: true,
    cellComponent: 'click',
    content: (record: any) => {
      if (record.files_json) {
        return record.files_json?.name;
      } else {
        return '';
      }
    },
    cb: (record: any) => {
      if (record.files_json && record.files_json.file_url) {
        const url: string =
          'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(record.files_json.file_url);
        window.open(url, '_blank');
        // axios
        //   .get('/' + record.files_json.file_url, {
        //     responseType: 'blob',
        //   })
        //   .then((res: any) => {
        //     const blob = res.data;
        //     // FileReader主要用于将文件内容读入内存
        //     const reader = new FileReader();
        //     reader.readAsDataURL(blob);
        //     // onload当读取操作成功完成时调用
        //     reader.onload = (e: any) => {
        //       const a = document.createElement('a');
        //       // 获取文件名fileName
        //       let fileName = res.config['url'].split('/');
        //       fileName = fileName[fileName.length - 1];
        //       // fileName = fileName.replace(/"/g, "");
        //       a.download = fileName;
        //       a.href = e.target.result;
        //       document.body.appendChild(a);
        //       a.click();
        //       document.body.removeChild(a);
        //     };
        //   });
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    },
  },
  {
    title: t('page.cerp.payslip.column.title.excel_files_json'),
    width: 200,
    dataIndex: 'excel_files_json',
    checked: true,
    cellComponent: 'click',
    content: (record: any) => {
      if (record.excel_files_json) {
        return record.excel_files_json?.name;
      } else {
        return '';
      }
    },
    cb: (record: any) => {
      if (record.excel_files_json && record.excel_files_json.file_url) {
        const url: string =
          'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(record.excel_files_json.file_url);
        window.open(url, '_blank');
        // axios
        //   .get('/' + record.excel_files_json.file_url, {
        //     responseType: 'blob',
        //   })
        //   .then((res: any) => {
        //     const blob = res.data;
        //     // FileReader主要用于将文件内容读入内存
        //     const reader = new FileReader();
        //     reader.readAsDataURL(blob);
        //     // onload当读取操作成功完成时调用
        //     reader.onload = (e: any) => {
        //       const a = document.createElement('a');
        //       // 获取文件名fileName
        //       let fileName = res.config['url'].split('/');
        //       fileName = fileName[fileName.length - 1];
        //       // fileName = fileName.replace(/"/g, "");
        //       a.download = fileName;
        //       a.href = e.target.result;
        //       document.body.appendChild(a);
        //       a.click();
        //       document.body.removeChild(a);
        //     };
        //   });
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    },
  },
  {
    title: t('page.cerp.payslip.column.title.invoice_type'),
    width: 100,
    dataIndex: 'invoice__invoice_type',
    checked: true,
    cellComponent: 'custom',
    field_1: t('工资'),
    field_2: t('报销'),
    field_3: 'credit note',
    field_4: t('加班'),
    field_5: t('CC报销'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('工资'), value: 1 },
      { text: t('报销'), value: 2 },
      { text: 'credit note', value: 3 },
      { text: t('加班'), value: 4 },
      { text: t('CC报销'), value: 5 },
    ],
  },
  {
    title: t('page.cerp.payslip.column.title.date'),
    width: 150,
    dataIndex: 'date',
    checked: true,
    content: (record: any) => {
      return getDate(record.date);
    },
  },
  {
    title: t('page.cerp.payslip.column.title.remark'),
    width: 150,
    dataIndex: 'remark',
    checked: true,
    cellComponent: 'remark',
  },
  {
    title: 'CC银行账户',
    width: 150,
    dataIndex: 'cc_bank_account_name',
    checked: true,
  },
  {
    title: t('page.cerp.payslip.column.title.transaction__name'),
    width: 150,
    dataIndex: 'transaction__name',
    checked: true,
  },
  {
    title: t('page.cerp.payslip.column.title.referral_fees'),
    width: 150,
    dataIndex: 'referral_fees',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('page.cerp.payslip.column.title.management_fee'),
    width: 150,
    dataIndex: 'management_fee',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('page.cerp.payslip.column.title.bank_charge_cost'),
    width: 150,
    dataIndex: 'bank_charge_cost',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('page.cerp.payslip.column.title.insurance_fee'),
    width: 150,
    dataIndex: 'insurance_fee',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('page.cerp.payslip.column.title.total_amount'),
    width: 150,
    dataIndex: 'total_amount',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('page.cerp.payslip.column.title.transaction__currency__currency'),
    width: 150,
    dataIndex: 'transaction__currency__currency',
    checked: true,
  },
  {
    title: t('page.cerp.payslip.column.title.founder'),
    width: 150,
    dataIndex: 'founder',
    checked: true,
  },
  {
    title: t('page.cerp.payslip.column.title.reviser'),
    width: 150,
    dataIndex: 'reviser',
    checked: true,
  },
  {
    title: t('page.cerp.payslip.column.title.create_time'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.create_time);
    },
  },
  {
    title: t('page.cerp.payslip.column.title.update_time'),
    width: 150,
    dataIndex: 'update_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },
  {
    title: t('page.cerp.payslip.column.title.is_active'),
    width: 150,
    dataIndex: 'is_active',
    checked: true,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },
  {
    title: t('page.cerp.payslip.column.title.is_valid'),
    width: 150,
    dataIndex: 'is_valid',
    checked: true,
    cellComponent: 'boolean',
    name1: '有效',
    name2: '无效',
    filterMultiple: false,
    filters: [
      { text: '有效', value: true },
      { text: '无效', value: false },
    ],
  },
  {
    title: t('page.cerp.payslip.column.title.action'),
    width: 220,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push(
  {
    type: 'custom',
    slotName: 'is_valid',
    field_true: '有效',
    field_false: '无效',
  },
  {
    type: 'custom',
    slotName: 'invoice_type',
    field_1: '工资',
    field_2: '报销',
    field_3: 'credit note',
    field_4: '加班',
    field_5: 'CC报销',
  },
  {
    type: 'custom',
    slotName: 'status',
    field_1: '新创建',
    field_2: '已发送',
    field_3: '已确认',
    field_4: '生成PDF',
  },
  {
    type: 'custom',
    slotName: 'payment_status',
    field_0: '未收到款项',
    field_1: '收到款项',
    field_2: '已付款',
    field_3: '垫付',
  },
  {
    type: 'custom',
    slotName: 'pdf_status',
    field_0: '未开始',
    field_1: '正在生成',
    field_2: '失败',
    field_3: '成功',
  },
  {
    type: 'custom',
    slotName: 'worker_status',
    field_1: '-无-',
    field_2: '工作中',
    field_3: '结束',
    field_4: '申请签证中',
    field_5: '尚未开始',
    field_6: '潜在雇员',
    field_7: '离职',
    field_8: '待定',
  },
  {
    type: 'color',
    slotName: 'email_record__status',
    cb: (record: any) => {
      if (record.email_record__status == 0 || record.email_record__status == 1) {
        return 'font-weight: bolder';
      } else if (record.email_record__status == 2) {
        return 'color: #FF0000;font-weight: bolder';
      }
    },
    content: (record: any) => {
      if (record.email_record__status == 1) {
        return t('发送成功');
      } else if (record.email_record__status == 2) {
        return t('发送失败');
      } else if (record.email_record__status == 0) {
        return t('已发送');
      }
    },
  },
  {
    type: 'click',
    slotName: 'files_json',
    content: (record: any) => {
      if (record.files_json) {
        return record.files_json?.name;
      } else {
        return '';
      }
    },
    cb: (record: any) => {
      if (record.files_json && record.files_json.file_url) {
        const url: string =
          'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(record.files_json.file_url);
        window.open(url, '_blank');
        // axios
        //   .get('/' + record.files_json.file_url, {
        //     responseType: 'blob',
        //   })
        //   .then((res: any) => {
        //     const blob = res.data;
        //     // FileReader主要用于将文件内容读入内存
        //     const reader = new FileReader();
        //     reader.readAsDataURL(blob);
        //     // onload当读取操作成功完成时调用
        //     reader.onload = (e: any) => {
        //       const a = document.createElement('a');
        //       // 获取文件名fileName
        //       let fileName = res.config['url'].split('/');
        //       fileName = fileName[fileName.length - 1];
        //       // fileName = fileName.replace(/"/g, "");
        //       a.download = fileName;
        //       a.href = e.target.result;
        //       document.body.appendChild(a);
        //       a.click();
        //       document.body.removeChild(a);
        //     };
        //   });
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    },
  },
  {
    type: 'click',
    slotName: 'excel_files_json',
    content: (record: any) => {
      if (record.excel_files_json) {
        return record.excel_files_json?.name;
      } else {
        return '';
      }
    },
    cb: (record: any) => {
      if (record.excel_files_json && record.excel_files_json.file_url) {
        const url: string =
          'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(record.excel_files_json.file_url);
        window.open(url, '_blank');
        // axios
        //   .get('/' + record.excel_files_json.file_url, {
        //     responseType: 'blob',
        //   })
        //   .then((res: any) => {
        //     const blob = res.data;
        //     // FileReader主要用于将文件内容读入内存
        //     const reader = new FileReader();
        //     reader.readAsDataURL(blob);
        //     // onload当读取操作成功完成时调用
        //     reader.onload = (e: any) => {
        //       const a = document.createElement('a');
        //       // 获取文件名fileName
        //       let fileName = res.config['url'].split('/');
        //       fileName = fileName[fileName.length - 1];
        //       // fileName = fileName.replace(/"/g, "");
        //       a.download = fileName;
        //       a.href = e.target.result;
        //       document.body.appendChild(a);
        //       a.click();
        //       document.body.removeChild(a);
        //     };
        //   });
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    },
  },
);

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_payslip_list,
    addData: create_payslip,
    updateData: update_payslip,
    removeData: delete_payslip,
    recoverData: recover_payslip,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};

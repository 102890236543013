import {
  create_customer,
  delete_customer,
  query_customer_list,
  recover_customer,
  update_customer,
} from '@/api/receipt-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/receipt/receipt-customer-config';
import { reactive } from 'vue';
import { useRoute } from 'vue-router';
import { getDateTime } from '@/utils/function';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '名称',
      label_i18n: t('名称'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名称',
      placeholder_i18n: t('请填写名称'),
    },
    {
      type: 'select',
      name: 'customer_type',
      label: '客户类型',
      label_i18n: t('客户类型'),
      placeholder: '请选择客户类型',
      placeholder_i18n: t('请选择客户类型'),
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      allowClear: true,
      datasource: 'customer_type',
    },
    {
      type: 'select',
      name: 'customer_attr',
      label: '客户属性',
      label_i18n: t('客户属性'),
      placeholder: '请选择客户属性',
      placeholder_i18n: t('请选择客户属性'),
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      allowClear: true,
      datasource: 'customer_attr',
    },
    {
      type: 'remotemodal',
      name: 'adviser_id',
      label: '隶属顾问',
      label_i18n: t('隶属顾问'),
      disabled: false,
      allowClear: true,
      defaultValue: '',
      labelKey: 'full_name',
      valueKey: 'id',
      placeholder: '隶属顾问',
      show_search: false,
      maxTagCount: 5,
      mode: 'default',
      title: t('选择隶属顾问'),
      modalType: 'cerp-adviser',
    },
    {
      type: 'remoteselect',
      name: 'recuitment_company_id',
      label: '顾问公司',
      label_i18n: t('顾问公司'),
      disabled: false,
      placeholder: '请选择顾问公司',
      placeholder_i18n: t('请选择顾问公司'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'company_name',
      valueKey: 'id',
      modalType: 'baseinfo-recuitmentcompany',
    },
    {
      type: 'remoteselect',
      name: 'cc_company_id',
      label: 'CC公司',
      label_i18n: t('CC公司'),
      disabled: false,
      placeholder: '请选择CC公司',
      placeholder_i18n: t('请选择CC公司'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-cccompany',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    customer_attr: null,
    sort_fields: ['-create_time'],
  }),
};
const search_options = {
  is_active: [
    { value: None, name: '全部' },
    { value: True, name: '可用' },
    { value: False, name: '禁用' },
  ],
  customer_type: [
    { value: 0, name: t('顾问') },
    { value: 1, name: t('顾问公司') },
    { value: 2, name: t('CC公司') },
    { value: 3, name: t('其他') },
  ],
  customer_attr: [
    { value: 0, name: t('个人') },
    { value: 1, name: t('公司') },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [],
  rules: {
    name: [{ required: true, message: '请填写票据合同模板名称' }],
  },
  model: reactive({
    org_id: current_org?.id,
    name: null,
    description: null,
    data: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    name: null,
    description: null,
    data: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: t('客户管理'),
  addModalTitle: '',
  updateTitle: '',
  is_create: false, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    customer_id: 'id',
  },
  delete: {
    customer_id: 'id',
  },
  recover: {
    customer_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    customer_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('序号'),
    dataIndex: 'index',
    width: 50,
    fixed: 'left',
  },
  {
    title: t('名称'),
    width: 150,
    dataIndex: 'name',
    checked: true,
    fixed: 'left',
  },
  {
    title: t('客户类型'),
    width: 150,
    dataIndex: 'customer_type',
    checked: true,
    fixed: 'left',
    content: (record: any, text: any) => {
      let color = '';
      switch (text) {
        case 0:
          color = t('顾问');
          break;
        case 1:
          color = t('顾问公司');
          break;
        case 2:
          color = t('CC公司');
          break;
        case 3:
          color = t('其他');
          break;
        default:
          color = '';
          break;
      }
      return color;
    },
  },
  {
    title: t('客户属性'),
    width: 150,
    dataIndex: 'customer_attr',
    checked: true,
    fixed: 'left',
    content: (record: any, text: any) => {
      let color = '';
      switch (text) {
        case 0:
          color = t('个人');
          break;
        case 1:
          color = t('公司');
          break;
        default:
          color = '';
          break;
      }
      return color;
    },
  },
  {
    title: t('ID'),
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('地址'),
    minWidth: 150,
    dataIndex: 'address',
    checked: true,
  },
  {
    title: t('电话'),
    width: 150,
    dataIndex: 'phone',
    checked: true,
  },
  {
    title: t('邮箱'),
    width: 150,
    dataIndex: 'email',
    checked: true,
  },
  {
    title: t('备注'),
    width: 150,
    dataIndex: 'mark',
    checked: true,
  },
  {
    title: t('是否自动发送付款凭证'),
    width: 150,
    dataIndex: 'is_auto_send',
    checked: true,
    cellComponent: 'boolean',
    name1: t('是'),
    name2: t('否'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('是'), value: true },
      { text: t('否'), value: false },
    ],
  },
  // {
  //   title: t('是否需要增扣提醒'),
  //   width: 150,
  //   dataIndex: 'is_need_extra',
  //   checked: true,
  //   cellComponent: 'boolean',
  //   name1: t('是'),
  //   name2: t('否'),
  //   filterMultiple: false,
  //   filters: [
  //     { text: t('全部'), value: '' },
  //     { text: t('是'), value: true },
  //     { text: t('否'), value: false },
  //   ],
  // },
  // {
  //   title: '额外数据',
  //   width: 100,
  //   dataIndex: 'data',
  //   checked: true,
  //   cellComponent: 'attributetable',
  // },
  {
    title: t('创建时间'),
    width: 130,
    dataIndex: 'create_time',
    checked: true,
    sorter: {
      multiple: 1,
    },
    content: (record: any) => {
      return getDateTime(record.create_time);
    },
  },
  {
    title: t('修改时间'),
    width: 130,
    dataIndex: 'update_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },
  {
    title: t('是否可用'),
    width: 150,
    dataIndex: 'is_active',
    checked: true,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },

  {
    title: t('操作'),
    width: 135,
    dataIndex: 'action',
    checked: true,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push();

export default () => {
  const route = useRoute();
  search_config.model = reactive({ ...search_config.model });
  search_config.model.ids = Number(route.query.ids);
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_customer_list,
    addData: create_customer,
    updateData: update_customer,
    removeData: delete_customer,
    recoverData: recover_customer,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
